import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Lightbox from "../components/lightBox";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Hero from "../components/HeroBanner";
import {
  FaClock,
  FaExchangeAlt,
  FaFileCode,
  FaMobile,
} from "react-icons/fa";
import {
  FcSearch,
  FcMoneyTransfer,
  FcPaid,
  FcQuestions,
  FcSynchronize,
  FcVoicePresentation,
  FcDocument,
  FcInTransit,
  FcDataSheet,
  FcInspection,
  FcFilingCabinet,
  FcSurvey,
  FcComboChart,
  FcNews,
  FcLandscape,
  FcFeedback,
  FcMultipleSmartphones,
  FcKindle,
  FcDataConfiguration,
  FcDataEncryption,
  FcViewDetails,
  FcGlobe,
} from "react-icons/fc";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    automico: file(relativePath: { eq: "automicob2b-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: FULL_WIDTH)
      }
    }
    laptop: file(relativePath: { eq: "laptop-b2b-questy-dwa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        kartab2b: file(relativePath: { eq: "karta-produktu-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        panelb2b: file(relativePath: { eq: "panel-kontrahenta-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        niezalogowanyb2b: file(relativePath: { eq: "b2b-klient-niezalogowany.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        heroBg: file(
      relativePath: { eq: "system-crm-questy.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
  }
`;

const Aura = ({ data }) => {
  return (
        <Layout>
      <SEO
        title="Sprzedaż B2B – Platformy B2B – zautomatyzuj sprzedaż"
        description="Sprzedaż B2B ✅ Platformy B2B • Dedykowane i komplementarne dla ERP rozwiązania e-commerce, standardowa platforma B2B z wieloma funkcjami"
      />
         <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Sprzedaż B2B – docieraj do klientów hurtowych online</h1>
        <h2>Zwiększ sprzedaż i popraw wyniki swojej firmy</h2>
      </Hero>
    <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do sprzedaży B2B w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do Twojej własnej, darmowej 30-dniowej wersji próbnej platformy sprzedażowej B2B</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować system do sprzedaży B2B
            </Link>
          </div>
          </div>
    </section>

       <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
  Doskonale zdajemy sobie sprawę, jak ważne w&nbsp;hurtowej sprzedaży B2B jest zapewnienie kontrahentom najwyższej 
  klasy obsługi. To właśnie od ich indywidualnego doświadczenia zależy cała Wasza przyszła relacja 
  biznesowa oraz&nbsp;konkurencyjność Twojej firmy na rynku. Platforma sprzedażowa B2B to&nbsp;narzędzie skupione na<strong> kompleksowym 
  wsparciu całego procesu sprzedaży hurtowej</strong>, które nie tylko usprawnia komunikację z klientem i automatyzuje sprzedaż 
  firmy, ale również pomaga handlowcom wykonywać ich pracę jeszcze lepiej.
</p>
</div>
</div>
</Container>
       <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Wiemy, że firmy znajdujące się na różnych etapach rozwoju potrzebują innych rozwiązań, dlatego w&nbsp;obszarze automatyzacji 
sprzedaży stworzyliśmy dwa różne systemy do sprzedaży B2B. Pierwszy z&nbsp;nich to<i> gotowy, prosty i&nbsp;szybki </i>we wdrożeniu 
system, który odpowiada na standardowe problemy i&nbsp;potrzeby firm zajmujących się sprzedażą hurtową (<a href="https://automicob2b.pl/">Automico B2B</a>). 
Drugi z&nbsp;nich jest<i> rozwiązaniem dedykowanym</i>, które poza platformą sprzedażową B2B może dostarczyć wiele innych możliwości, 
pozwalających na kompleksową samoobsługę klienta biznesowego, jak&nbsp;i&nbsp;zarządzanie całym przedsiębiorstwem (<a href="https://aurabusiness.pl/">Aura Business</a>).
Najważniejszą cechą obu tych systemów do sprzedaży B2B jest ich<strong><i> elastyczność</i></strong>, a naszą –<strong><i> doświadczenie </i></strong>w&nbsp;realizacji projektów dla wielu firm w różnych branżach.
</p>
</div>
          <div className="text-block" style={{
          paddingTop: 20,
        }}>
            <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.laptop]}
        alts={["System do sprzedaży B2B – Automico B2B"]}
      />
</div>
</div>
</Container>
   <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Na rynku rozwiązań informatycznych działamy już od&nbsp;2002 roku, wszechstronnie wspierając zarówno<strong> sektor małych i średnich 
przedsiębiorstw, jak i&nbsp;duże firmy działające w wielu krajach</strong>. Dzięki wsłuchiwaniu się w potrzeby naszych klientów oraz&nbsp;skupieniu 
na dostarczaniu elastycznych rozwiązań, jesteśmy w&nbsp;stanie nieustannie rozwijać nasze systemy do sprzedaży B2B i stale 
podwyższać ich jakość. Zdajemy sobie również sprawę, że to platforma sprzedażowa powinna dopasować się do przedsiębiorstwa, a nie 
ono do niej. Wszystko to&nbsp;umożliwia nam dostarczanie rozwiązań B2B dostosowanych do potrzeb każdego przedsiębiorstwa, które 
zajmuje się sprzedażą hurtową lub pragnie rozszerzyć swoją działalność również o ten segment.
</p>
</div>
</div>
</Container>

 <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Nasze systemy do sprzedaży B2B rozwiązują wszystkie najważniejsze problemy występujące w&nbsp;sprzedaży hurtowej:</strong></p>
<p><ul>
<li>Konieczność ręcznego gromadzenia zamówień od klientów.</li>
<li>Manualne dodawanie zamówień do systemu ERP, powodujące występowanie błędów.</li>
<li>Chaos i brak uporządkowania w obszarze zamówień.</li>
<li>Konieczność indywidualnego informowania kontrahentów o cenach i&nbsp;rabatach.</li>
<li>Problemy z obsługą kontaktów długookresowych. </li>
<li>Niespójność procesu obsługi zapytań ofertowych oraz negocjowania cen.</li>
<li>Zajmujące wiele czasu ofertowanie klientów końcowych.</li>
<li>Brak możliwości składania zamówień przez klientów poza godzinami pracy firmy. </li>
<li>Długotrwała obsługa pojawiających się zapytań ofertowych.</li>
<li>Czasochłonne i skomplikowane tworzenie raportów.</li>
<li>Problemy ze sprawnym udostępnianiem klientom dokumentów handlowych.</li>
<li>Nieefektywna komunikacja wewnątrz firmy oraz&nbsp;z&nbsp;kontrahentami.</li>
</ul></p>
</div>
  <div className="text-block">
  <p><strong>Niektóre z korzyści wynikających z wdrożenia systemu do sprzedaży B2B w firmie:</strong></p>
<p><ul>
<li>Możliwość prowadzenia sprzedaży B2B i B2C za&nbsp;pomocą jednego systemu. </li>
<li>Zautomatyzowanie obsługi kontrahentów i&nbsp;wyposażenie ich w funkcjonalny system do samoobsługi. </li>
<li>Stały dostęp klientów do kompletnej, aktualnej oferty produktowej oraz&nbsp;indywidualnych cen. </li>
<li>Krótszy czas obsługi wielu różnych procesów działu handlowego.</li>
<li>Dostęp do szeregu funkcji wspierających promowanie i eksponowanie produktów. </li>
<li>Automatyzacja w zakresie przekazywania informacji o rozliczeniach. </li>
<li>Automatyczna integracja kluczowych danych i&nbsp;dokumentów pomiędzy systemem do sprzedaży B2B a programem ERP.</li>
<li>Oszczędność czasu i energii pracowników oraz&nbsp;zauważalnie niższy koszt obsługi klienta. </li>
<li>Pakiet automatycznych zestawień i&nbsp;raportów, które wspierają analizy sprzedażowe. </li>
<li>Możliwość sprawnej obsługi popularnych w B2B kredytów kupieckich.</li>
</ul></p>          
</div>
</div>
</Container>
  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do sprzedaży B2B w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do Twojej własnej, darmowej 30-dniowej wersji próbnej systemu do sprzedaży B2B</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować system do sprzedaży B2B
            </Link>
          </div>
          </div>
    </section>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
 <h2
 style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}
  >Najważniejsze funkcje naszych systemów do sprzedaży B2B:</h2>

<p>Głównym zadaniem dobrego systemu do sprzedaży B2B jest<strong> czuwanie nad wszystkimi procesami handlowymi </strong>związanymi 
z obsługą wielu różnych partnerów biznesowych – producentów, dostawców, hurtowników oraz sieci 
detalistów. Rozwiązanie oferuje wsparcie na etapie całego procesu, od&nbsp;stwierdzenia zapotrzebowania i określenia 
możliwości współpracy, przez ofertowanie i dobór odpowiednich produktów, aż po samo składanie zamówienia i&nbsp;jego 
realizację w platformie sprzedażowej. Przedstawiamy najważniejsze z&nbsp;funkcji, które posiada system do sprzedaży B2B w&nbsp;ramach naszego rozwiązania.
</p>
</div>

</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<h3>Spersonalizowana oferta produktowa </h3>

<p>Zapewnienie kontrahentom stałego dostępu do pełnej oferty produktowej to podstawowa funkcjonalność 
platformy sprzedażowej B2B, która umożliwia realizowanie celów handlowych. Dzięki<strong> nowoczesnemu interfejsowi </strong>systemu do 
sprzedaży B2B możesz przedstawiać wszystkie informacje o&nbsp;produktach w sposób jak najlepiej dopasowany do Twojego biznesu.
</p>
<p>Z łatwością<strong> wyróżnij swoją ofertę </strong>za pomocą list i&nbsp;znaczników, dzięki którym możesz pogrupować produkty 
wskazując, na przykład, na te najbardziej popularne, objęte promocją lub będące nowością w&nbsp;Twoim sklepie. 
Nad&nbsp;standardową kartoteką produktów w platformie sprzedażowej możesz dodatkowo umieścić atrakcyjny panel wyświetlający wszystkie 
promowane towary, dzięki czemu skupisz uwagę swoich klientów tam, gdzie jest to&nbsp;najistotniejsze.</p>

</div>
    <div className="text-block" style=
    {{
    	paddingTop:20,
    }}>
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.kartab2b]}
        alts={[
          "Karta produktu w systemie do sprzedaży B2B",
        ]}
      />
    </div>
</div>
</Container>
 <Container>
         <div className="text-block">
 <p>Kluczowym elementem spersonalizowanej oferty produktowej jest również definiowanie<strong> indywidualnych 
 cenników i rabatów </strong>dla klientów, zgodnie z&nbsp;Waszymi wspólnymi ustaleniami. Zachowasz dzięki temu 
 spójność komunikacji i&nbsp;odpowiednio zadbasz o relacje z&nbsp;kontrahentami Twojej firmy. 
</p>
</div>
</Container>
 <Container className="container container--text">
    <div className="text-answers">
        
          <div className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.panelb2b]}
        alts={[
          "Panel kontrahenta w systemie do sprzedaży B2B",
        ]}
      />
</div>
    <div className="text-block">
    <h3>Panel kontrahenta B2B</h3>
<p>To pulpit, który Twoi klienci biznesowi ujrzą tuż po zalogowaniu się do platformy sprzedażowej B2B. Pozwala on na&nbsp;wyświetlanie w jednym 
miejscu<strong> wszystkich najważniejszych dla kontrahentów informacji</strong>: od&nbsp;produktów promowanych i banerów reklamowych, przez wszelkie 
dane dotyczące Waszej współpracy i&nbsp;statusy aktualnych zamówień.</p>
<p>Dzięki możliwości dowolnego konfigurowania wyglądu i zawartości,<strong> to Ty decydujesz</strong>, co znajdzie się na pulpicie 
kontrahentów i do jakich informacji będą mieli oni dostęp za jego pośrednictwem. To&nbsp;świetny sposób na budowanie 
relacji z klientami poprzez udostępnianie im najświeższych informacji dotyczących Twojego systemu do sprzedaży 
B2B i&nbsp;znajdującej się w nim oferty produktowej. </p>
    </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">

<h3>Oferta dla klientów B2C i oferta bez zalogowania</h3>
<p>
System do sprzedaży B2B jest ergonomicznym rozwiązaniem skupionym na klientach biznesowych, które jednak umożliwia 
również<strong> docieranie do klientów indywidualnych</strong>. Nowoczesna i w pełni responsywna platforma sprzedażowa B2B umożliwi Ci zarządzanie 
wyglądem Twojego sklepu w taki sposób, by inaczej wyświetlał się on klientom biznesowym, a inaczej – detalicznym. 
Z łatwością możesz dzięki temu, na przykład, ukryć ceny wszystkim niezalogowanym klientom lub dowolnie ustawiać proces płatności online.
</p>
<p>
  <strong>Dowolnie konfigurowalna oferta dostępna bez zalogowania </strong>pozwoli na docieranie do nowych grup klientów oraz poszerzanie 
  działalności przedsiębiorstwa. Za jej pośrednictwem<strong> podniesiesz również zadowolenie obecnych kontrahentów</strong>, którzy czasem 
  potrzebują szybkiej możliwości podglądu kartoteki produktów bez konieczności logowania się w systemie do sprzedaży B2B.
</p>
</div>
    
           <div className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          paddingTop: 25,
          margin: "0 auto",
        }}
        images={[data.niezalogowanyb2b]}
        alts={[
          "Panel klienta niezalogowanego w systemie sprzedażowym B2B",
        ]}
      />
    </div>
</div>
</Container>

<h3
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Poznaj również pozostałe funkcje systemu do sprzedaży B2B</h3>

  <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcSearch size="70px" />
            <br />
            <h3>Inteligentna wyszukiwarka</h3>
            <p>Pozwól klientowi zawsze znaleźć to, czego szuka</p>
          </Tile>
           <Tile>
            <FcMoneyTransfer size="70px" />
            <br />
            <h3>Płatności <br />online</h3>
            <p>Spełniaj najwyższe standardy profesjonalnej sprzedaży B2B</p>
          </Tile>
          <Tile>
            <FcPaid size="70px" color="#999999" />
            <br />
            <h3>Zamówienia<br /> hurtowe</h3>
            <p>
             Umożliw klientom szybkie składanie i&nbsp;ponawianie zamówień hurtowych
            </p>
          </Tile>
          <Tile>
            <FcSynchronize size="70px" color="#999999" />
            <br />
            <h3>Integracja z&nbsp;programem handlowym</h3>
            <p>
              Dwustronna wymiana danych z wszystkimi najpopularniejszymi systemami
            </p>
          </Tile>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcQuestions size="70px" />
            <br />
            <h3>Zapytania ofertowe i&nbsp;obsługa kontraktów</h3>
            <p>Obsługa zakupów handlowych tak sprawna, jak&nbsp;to możliwe</p>
          </Tile>
           <Tile>
            <FcVoicePresentation size="70px" />
            <br />
            <h3>Ofertowanie klienta końcowego</h3>
            <p>Zachęć klientów do większych zakupów i&nbsp;zyskaj ich lojalność</p>
          </Tile>
          <Tile>
            <FcDocument size="70px" color="#999999" />
            <br />
            <h3> Faktury <br />i rozliczenia</h3>
            <p>
             Zawsze aktualne informacje, dostępne również dla kontrahenta
            </p>
          </Tile>
          <Tile>
            <FcInTransit size="70px" color="#999999" />
            <br />
            <h3> Minimum <br />
              logistyczne</h3>
            <p>
              Zwiększ kontrolę nad swoimi zamówieniami
            </p>
          </Tile>
        </div>

                <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcDataSheet size="70px" />
            <br />
            <h3>Panel handlowca</h3>
            <p>Usprawnij wszystkie aktywności swoich pracowników</p>
          </Tile>
           <Tile>
            <FcInspection size="70px" />
            <br />
            <h3>Zamówienia handlowców</h3>
            <p>Sposób na klientów stroniących od zakupów online</p>
          </Tile>
          <Tile>
            <FcFilingCabinet size="70px" color="#999999" />
            <br />
            <h3>Kartoteki kontrahentów</h3>
            <p>
             Wszystkie kluczowe informacje w jednym miejscu
            </p>
          </Tile>
          <Tile>
            <FcSurvey size="70px" color="#999999" />
            <br />
            <h3>Sprawne negocjacje cen</h3>
            <p>
              Wygodna negocjacja możliwa o każdej porze
            </p>
          </Tile>
        </div>

         <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcComboChart size="70px" />
            <br />
            <h3> Analiza <br />sprzedaży</h3>
            <p>Twoje wsparcie w&nbsp;podejmowaniu trafnych decyzji</p>
          </Tile>
           <Tile>
            <FcNews size="70px" />
            <br />
            <h3>Ogłoszenia <br />i promocje</h3>
            <p>Najwyższej klasy komunikacja z&nbsp;kontrahentami</p>
          </Tile>
          <Tile>
            <FcLandscape size="70px" color="#999999" />
            <br />
            <h3>Promocje, rabaty i&nbsp;banery</h3>
            <p>
             Zdobądź uwagę klienta i&nbsp;zwiększ sprzedaż
            </p>
          </Tile>
          <Tile>
            <FcKindle size="70px" color="#999999" />
            <br />
            <h3>Newsletter</h3>
            <p>
              Spersonalizowane wiadomości do Twoich kontrahentów
            </p>
          </Tile>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcMultipleSmartphones size="70px" />
            <br />
            <h3>Komunikator</h3>
            <p>Kompleksowo usprawnij komunikację w&nbsp;Twojej firmie</p>
          </Tile>
           <Tile>
            <FcFeedback size="70px" />
            <br />
            <h3>Powiadomienia mailowe</h3>
            <p>Zautomatyzuj powiadamianie klientów</p>
          </Tile>
          <Tile>
            <FcDataEncryption size="70px" color="#999999" />
            <br />
            <h3>Zarządzanie <br />uprawnieniami</h3>
            <p>
             Dowolnie dostosuj system do potrzeb użytkowników
            </p>
          </Tile>
          <Tile>
            <FcDataConfiguration size="70px" color="#999999" />
            <br />
            <h3>Personalizacja pulpitu pracownika</h3>
            <p>
              Wygoda i dopasowanie do&nbsp;codziennych aktywności
            </p>
          </Tile>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcViewDetails size="70px" />
            <br />
            <h3>Indywidualne szablony dokumentów</h3>
            <p>Zadbaj o wizerunek Twojej firmy</p>
          </Tile>
           <Tile>
            <FcGlobe size="70px" />
            <br />
            <h3>Wielowalutowość i&nbsp;wielojęzyczność</h3>
            <p>Podnieś jakość obsługi zagranicznych klientów</p>
          </Tile>
        </div>
        </Container>
  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do sprzedaży B2B w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do Twojej własnej, darmowej 30-dniowej wersji próbnej systemu do sprzedaży B2B</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować system do sprzedaży B2B
            </Link>
          </div>
          </div>
    </section>
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Co wyróżnia nasze platformy sprzedażowe B2B?</h2>
 <Container>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaFileCode size="70px" color="#86BC2A" />}
          title="Brak limitów w zakresie produktów, kontrahentów i&nbsp;zamówień"
        />
        <BenefitBlock
          icon={<FaExchangeAlt size="70px" color="#86BC2A" />}
          title="Szerokie możliwości integracji z&nbsp;programami ERP"
        />
        <BenefitBlock
          icon={<FaClock size="70px" color="#86BC2A" />}
          title="Szybkość wdrożenia, profesjonalna opieka posprzedażowa i&nbsp;szkolenia"
        />
        <BenefitBlock
          icon={<FaMobile size="70px" color="#86BC2A" />}
          title="Opcja rozbudowy systemu do&nbsp;rozwiązania dedykowanego"
        />
      </BenefitBlockWrapper>
    </Container>

<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Czym różni się Automico B2B od Aura Business? Kto z nich skorzysta?</h2>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Automico B2B </strong>to gotowa i prosta platforma sprzedażowa B2B, która<strong> zautomatyzuje całą sprzedaż Twojego przedsiębiorstwa</strong>. 
Umożliwi ona Twoim klientom samodzielne zakupy przez 24h/7 dni w tygodniu oraz obniży koszty komunikacji z klientem. 
Twoi kontrahenci będą mieli dostęp do atrakcyjnej i aktualnej oferty produktowej, informacjach o fakturach i&nbsp;rozliczeniach, 
promocjach czy&nbsp;indywidualnych warunkach handlowych. System do sprzedaży B2B współpracuje z&nbsp;programami handlowo-magazynowymi. 
Najczęstszymi klientami tego obszaru są firmy sektora MŚP oraz większe przedsiębiorstwa, które chcą rozpocząć 
współpracę od&nbsp;natychmiastowego wdrożenia gotowego rozwiązania i stopniowo je rozwijać w kierunku dedykowanego 
systemu Aura Business.</p>
</div>
 <div className="text-block"  style={{
                textAlign: "center",
                margin: "10px",
                      paddingTop: "100px",

              }}
            >
        
<a href="https://automicob2b.pl/">
            <GatsbyImage
              image={data.automico.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 250, 
                              
              }}
              alt="System do sprzedaży B2B - szybkie wdrożenie - Automico B2B"
            />
          </a>
 </div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Aura Business </strong>to<strong> znacznie większe, komplementarne dla ERP narzędzie wsparcia sprzedaży, marketingu i zarządzania 
usługami</strong>. Posiada ogromne możliwości związane indywidualną konfiguracją i&nbsp;integracjami. System do sprzedaży B2B w tym 
zakresie przeznaczony jest dla firm, które mają niestandardowe potrzeby. Aura Business to&nbsp;jednak nie tylko 
rozwiązanie e-commerce. System posiada wiele innych modułów, które możemy dostosować do Twojej firmy i które 
pozwolą na kompleksowe zarządzanie całym Twoim biznesem. </p>
</div>
 <div className="text-block"  style={{
                textAlign: "center",
                margin: "10px",
                paddingTop: "60px",

              }}
          >
<a href="https://aurabusiness.pl/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 270, 
              }}
              alt="Dedykowany system do sprzedaży B2B - Aura Business"
            />
          </a>
</div>
</div>
</Container>

<Container className="container container--text">
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
  }}
  >Chętnie dzielimy się wiedzą – tworzymy materiały dla Ciebie</h2>
    <div className="text-answers">
         <div className="text-block">
         <p>Jako doświadczony zespół nie boimy się wyzwań, wśród których znalazło się również wystąpienie przed kamerą 
         dla szerszej publiki. :) Tworzymy materiały traktujące o szeroko rozumianym zagadnieniu efektywnej sprzedaży 
         hurtowej online – chcemy podzielić się zdobywanym latami doświadczeniem i&nbsp;wiedzą o&nbsp;systemach do sprzedaży B2B, by&nbsp;potencjalnie 
         poszerzyć również i&nbsp;Twój zasób informacji i zainspirować Cię do dalszego rozwoju Twojej organizacji. Oto&nbsp;niektóre 
         z&nbsp;naszych treści, które mogą Ci pomóc w sprzedaży hurtowej online.</p>
</div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
        <h3>Co to jest platforma B2B?</h3>

<p>Pierwszy z naszych materiałów wideo poświęconych systemom do sprzedaży B2B, omawiający podstawowe 
zagadnienie: czym właściwie jest platforma B2B oraz jaka jest jej funkcjonalność? Które z funkcji 
systemu do sprzedaży hurtowej online mogą pomóc w efektywnym wsparciu działań handlowych oraz co 
warto wiedzieć przed dokonaniem wyboru takiej platformy?</p>
<p>Na wszystkie te pytania odpowiada Joanna Cieluch, Marketing Manager w&nbsp;Questy. 
Obejrzyj już teraz i&nbsp;poznaj wszystkie niezbędne informacje dotyczące tego typu rozwiązań.</p>
</div>
         <div className="text-block">
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="P04WxsFvuE8"
              title="Co to jest platforma B2B i co warto wiedzieć przed zakupem"/>
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
        <h3>5 kroków skutecznego wdrożenia platformy B2B</h3>

<p>Jeżeli znasz już wszystkie kluczowe informacje dotyczące systemu do&nbsp;sprzedaży B2B 
oraz wybrałeś już platformę, którą chcesz wdrożyć w&nbsp;Twojej firmie, warto, abyś poznał 
pięć kroków, które zmaksymalizują szansę na udane przeprowadzenie takiego wdrożenia. 
Sam zakup nie gwarantuje bowiem sukcesu, a przysposobienie systemu do&nbsp;sprzedaży 
B2B w firmie często wiąże się z&nbsp;wieloma wyzwaniami dla pracowników, klientów oraz 
samego przedsiębiorstwa. </p>
<p>Szerzej temu zagadnieniu przygląda się Marta Kaźmierska, Business Development Manager w&nbsp;Questy, 
która odpowiada za wdrożenia systemu Automico B2B. </p>
</div>
   <div className="text-block" style={{
                padding: "30px",
              }}>
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="HnRLj0RH4DI"
              title="Skuteczne wdrozenie platformy B2B w Twojej firmie: 5 kroków gwarantujących sukces"
            />
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
    <h3>Integracja B2B z ERP: Dlaczego warto integrować B2B z ERP</h3>

<p>Nasze systemy do sprzedaży B2B oferują szereg integracji z wszystkimi popularnymi na rynku programami 
handlowo-magazynowymi, w&nbsp;tym&nbsp;z&nbsp;oprogramowaniami: Subiekt GT, Subiekt NEXO, Symfonia ERP, WAPRO ERP, 
Comarch Optima, Comarch ERP XL, Navireo czy Enova. W&nbsp;przypadku rozwiązania dedykowanego zakres ten jest 
znacznie większy, obejmując dodatkowo na&nbsp;przykład SAP ERP, Microsoft Dynamics, Exact ERP, Streamsoft i wiele 
innych. Obsługujemy również interfejs programowania aplikacji API i&nbsp;nieustannie tworzymy nowe integracje – nie 
przejmuj się więc, jeżeli wśród listy integracji nie znalazłeś programu używanego w Twojej firmie.</p>
<p>
Piotr Szudrzyński, Business Devepolment Manager w&nbsp;Questy, przedstawia przebieg samego procesu 
integracji oraz omawia zakres wymiany danych i&nbsp;dokumentów pomiędzy systemami.</p>
</div>
    <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="sS8OF33PvTU"
              title="Dlaczego warto zdecydować się na integrację sklepu B2B z systemem ERP?"
            />
          </div>
</div>
</Container>
  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do sprzedaży B2B w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do Twojej własnej, darmowej 30-dniowej wersji próbnej systemu do sprzedaży B2B</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować system do sprzedaży B2B
            </Link>
          </div>
          </div>
    </section>
    </Layout>
  );
};

export default Aura;
